<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      responsive
      :items="quotes"
      :fields="fields"
    >
      <template #cell(folio)="data">
        <div class="text-primary font-weight-bolder">
          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder"
            :to="handleRouter(data.item)"
          >
            #{{ data.value }}
          </b-button>
        </div>
      </template>
      <template #cell(published_at)="data">
        {{ data.value | date }}
      </template>
      <template #cell(quote_status)="data">
        <b-badge :variant="$helpers.quoteStatusColor(data.value)">
          {{ $helpers.quoteStatusName(data.value) }}
        </b-badge>
      </template>
      <template #cell(payment_status)="data">
        <b-badge :variant="$helpers.paymentStatusColor(data.value)">
          {{ $helpers.paymentStatusName(data.value) }}
        </b-badge>
      </template>
      <template #cell(is_invoiced)="data">
        <div class="d-flex align-items-center">
          <b-badge
            :variant="data.value ? 'success' : 'secondary'"
            class="w-100"
          >
            {{ data.value ? $t('invoiced') : $t('pending') }}
          </b-badge>
          <b-button
            variant="primary"
            class="p-0 ml-1 custom-padding"
            @click="
              $router.push({
                name: 'quoteInvoices',
                params: { quoteId: data.item.id },
              })
            "
          >
            <feather-icon
              icon="EyeIcon"
              class="text-white"
              size="18"
              color="#000"
            />
          </b-button>
          <b-button
            variant="primary"
            class="p-0 custom-padding"
            style="margin-left:6px"
            @click="openModal(data.item)"
          >
            <feather-icon
              icon="UploadIcon"
              class="text-white"
              size="18"
            />
          </b-button>
        </div>
      </template>
      <template #cell(is_supplier_fully_invoiced)="data">
        <div class="d-flex align-items-center">
          <b-badge
            :variant="data.value ? 'success' : 'secondary'"
            class="w-100"
          >
            {{ data.value ? $t('invoiced') : $t('pending') }}
            {{ data.item.invoiced_purchase_orders_count }} /
            {{ data.item.total_purchase_orders }}
          </b-badge>
          <b-button
            variant="primary"
            class="p-0 ml-1 custom-padding"
            @click="
              $router.push({
                name: 'purchaseOrders',
                params: { quoteId: data.item.id },
              })
            "
          >
            <feather-icon
              icon="EyeIcon"
              class="text-white"
              size="18"
              color="#000"
            />
          </b-button>
        </div>
      </template>
      <template #cell(total)="data">
        $ {{ data.value | money }}
      </template>
      <template
        v-if="!noTools"
        #cell(tools)=""
      >
        <b-dropdown
          id="dropdown-1"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <!-- <b-dropdown-item @click="removeQuote(data.item.id)">{{
            $t('delete')
          }}</b-dropdown-item> -->
          <!-- <b-dropdown-item
            :to="{ name: 'editQuote', params: { quoteId: data.item.id } }"
          >{{ $t('edit') }}</b-dropdown-item>
          <b-dropdown-item
            :to="{
              name: 'addProductsToQuote',
              params: { quoteId: data.item.id },
            }"
          >{{ $t('add', { type: $t('products') }) }}</b-dropdown-item> -->
        </b-dropdown>
      </template>
    </b-table>
    <b-modal
      id="newInvoice"
      ok-only
      :ok-title="$t('save')"
      @ok="saveFiles"
    >
      <div class="text-center mb-2">
        <h3>{{ $t('uploadYourInvoice') }}</h3>
      </div>
      <b-row>
        <b-col sm="12">
          <b-form-group>
            <b-form-datepicker
              v-model="newInvoice.date"
              :placeholder="$t('date')"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          class="mt-1"
        >
          <dropzone
            height="150"
            :multiple="true"
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Dropzone from '@/components/dropzone/Dropzone.vue'

export default {
  components: {
    BTable,
    Dropzone,
  },
  props: {
    quotes: {
      type: Array,
      default() {
        return []
      },
    },
    noTools: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      newInvoice: {
        quoteId: null,
        name: '',
        folio: '',
        file: null,
        date: null,
      },
      fields: [
        {
          key: 'folio',
          label: 'Folio',
        },
        {
          key: 'published_at',
          label: this.$t('publishedDate'),
        },
        {
          key: 'quote_status',
          label: this.$t('status'),
        },
        {
          key: 'payment_status',
          label: this.$t('paymentStatus'),
        },
        {
          key: 'is_invoiced',
          label: this.$t('customerInvoice'),
        },
        {
          key: 'is_supplier_fully_invoiced',
          label: this.$t('supplierInvoice'),
        },
        {
          key: 'total',
          label: this.$t('total'),
        },
        {
          key: 'tools',
          label: this.$t('actions'),
        },
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('suppliers', {
      files: 'newProviderFiles',
    }),
  },

  created() {
    if (this.noTools) {
      const index = this.fields.findIndex(f => f.key === 'tools')
      this.fields.splice(index, 1)
    }
  },

  methods: {
    ...mapActions('quotes', [
      'deleteQuote',
      'fetchQuotes',
      'createQuoteInovice',
    ]),

    removeQuote(quoteId) {
      this.deleteQuote(quoteId).then(() => {
        this.fetchQuotes({ by_project: this.$route.params.projectId })
      })
    },

    handleRouter(quote) {
      // admin routes
      if (this.currentUser.role_name === 'admin') {
        if (
          (quote.quote_status === 'bid_approved'
            || quote.quote_status === 'buyout')
          && (quote.payment_status === 'payment_approved' || quote.payment_status === 'credit_approved')
        ) {
          return {
            name: 'purchaseOrder',
            params: { quoteId: quote.id },
          }
        }
        if (quote.quote_status === 'bidding') {
          return {
            name: 'firstRound',
            params: { quoteId: quote.id },
            query: { branchId: this.$route.params.id },
          }
        }
      }
      // auctioneer routes
      if (
        this.currentUser.role_name === 'auctioneer'
        || this.currentUser.role_name === 'supplier_admin'
      ) {
        if (
          (quote.quote_status === 'bid_approved'
            || quote.quote_status === 'buyout')
          && (quote.payment_status === 'payment_approved' || quote.payment_status === 'credit_approved')
        ) {
          return {
            name: 'purchaseOrder',
            params: { quoteId: quote.id },
          }
        }
        if (quote.quote_status === 'bidding') {
          return {
            name: 'firstRound',
            params: { quoteId: quote.id },
            query: { branchId: this.$route.params.id },
          }
        }
      }
      if (
        quote.is_published
        && (quote.quote_status === 'pending'
          || quote.quote_status === 'awaiting_customer_response')
      ) {
        return { name: 'prequote', params: { quoteId: quote.id } }
      }
      if (quote.quote_status === 'pending') {
        return {
          name: 'addProductsToQuote',
          params: { quoteId: quote.id },
        }
      }
      if (quote.quote_status === 'buyout') {
        return {
          name: 'checkout',
          params: { quoteId: quote.id },
        }
      }
      if (quote.quote_status === 'bidding') {
        return {
          name: 'auctionInProgress',
          params: { quoteId: quote.id },
        }
      }
      if (quote.quote_status === 'awaiting_bid_approval') {
        return {
          name: 'clientQuote',
          params: { id: quote.id },
        }
      }
      if (quote.quote_status === 'bid_approved') {
        return {
          name: 'checkout',
          params: { quoteId: quote.id },
        }
      }
      return {
        name: 'checkout',
        params: { quoteId: quote.id },
      }
    },
    openModal(quote) {
      this.newInvoice = {
        quoteId: quote.id,
        name: '',
        folio: '',
        file: null,
        date: new Date(),
      }
      this.$bvModal.show('newInvoice')
    },
    saveFiles() {
      const files = new FormData()
      this.files.forEach(file => {
        files.append('invoice_attachments[]', file)
      })
      files.append('invoiced_at', this.newInvoice.date)
      this.createQuoteInovice({
        quoteId: this.newInvoice.quoteId,
        attachments: files,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('filesSaved'),
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
        this.$emit('refetch')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-padding {
  padding: 1px !important;
}
</style>
